
export const SHOP_GQL_PROPERTIES = `
  uuid
  type
  name
  prettyId
  ownerUuid
  vat
  geolocation
  radius
  addressLine1
  addressLine2
  city
  state
  countryCode
  postcode
  phoneNumber
  stripeAccountId
  forcedStatus
  deliveryFee
  imageUrl
  largeImageUrl
  openingSchedule
  deliveryFleet
  live
  open
  createdAt
`;

export interface ShopResponse {
  shop: Shop;
}

export interface Shop {
  uuid: string;
  type: string;
  typeEnum: string;
  name: string;
  prettyId: string;
  ownerUuid: string;
  vat: number;
  geolocation: Geolocation;
  radius: number;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  countryCode: string;
  postcode: string;
  phoneNumber: string;
  deliveryFee: number;
  imageUrl: string;
  largeImageUrl: string;
  openingSchedule: OpeningSchedule;
  deliveryFleet: string;
  live: boolean;
  open: boolean;
  forcedStatus: boolean;
  createdAt: string;
}

export interface Geolocation {
  latitude: number;
  longitude: number;
};

export const ALL_SHOP_OPENING_HOUR_KEYS: ShopOpeningHourKey[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export type ShopOpeningHourKey = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
export interface OpeningSchedule {
  monday: OpeningHours;
  tuesday: OpeningHours;
  wednesday: OpeningHours;
  thursday: OpeningHours;
  friday: OpeningHours;
  saturday: OpeningHours;
  sunday: OpeningHours;
};

export interface OpeningHours {
  fromHour: number;
  fromMinute: number;
  toHour: number;
  toMinute: number;
};