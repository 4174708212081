import gql from 'graphql-tag';
import { SHOP_GQL_PROPERTIES } from 'models/Shop';

export const GET_SHOP = gql`
  query getShop($id: string) {
    shop(id: $id) @rest(type: "Shop", method: "GET", path: "/shop/{args.id}", endpoint: "ss") {
      ${SHOP_GQL_PROPERTIES}
    }
  }
`;

export const UPDATE_SHOP = gql`
  mutation updateShop($input: any) {
    shop(input: $input) @rest(type: "Shop", method: "PATCH", path: "/shop", endpoint: "ss") {
      ${SHOP_GQL_PROPERTIES}
    }
  }
`;

export const FORCE_SHOP_STATUS = gql`
  mutation forceShopStatus($shopId: String, $status: Boolean) {
    status(shopId: $shopId, status: $status, input: {})
    @rest(method: "PUT", path: "/shop/{args.shopId}/forcedStatus?status={args.status}", endpoint: "ss") {
      body
      statusCode
    }
  }
`;