import React from 'react';
import Router from 'next/router';
import useAuth from 'contexts/auth/auth.context';
import {
  Container
} from './NavBar.style';
import Navbar from 'react-bootstrap/Navbar';

type NavBarProps = {
  onMenuClick: () => void;
  reference: React.MutableRefObject<any>;
};

const NavBar: React.FC<NavBarProps> = ({
  onMenuClick,
  reference,
}) => {
  return (
    <Container ref={reference}>
      <Navbar bg="light" sticky="top">
        <Navbar.Brand href="#" onClick={() => onMenuClick()}>
          &#9776;
        </Navbar.Brand>
      </Navbar>
    </Container>
  );
};

export default NavBar;