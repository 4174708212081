import styled from 'styled-components';

export const Container = styled.div`
  .navbar {
    background-color: #fff !important;
    border-bottom: 2px #eee solid;
    z-index: 1000;
  }

  .brand {
    margin-left: 5px;
    
    img {
      height: 35px;
    }
  }
`;

export const Details = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;

  p {
    color: var(--primary-label-color);
    margin: 0;
  }

  span {
    font-size: 14px;
    color: var(--secondary-label-color);
  }
`;