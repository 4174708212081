import React from 'react';
import { useRouter } from 'next/router';
import useAuth from 'contexts/auth/auth.context';
import {
  Container,
  MobileHeader,
  NavLink,
  Spacer,
} from './Sidebar.style';
import Link from 'next/link';
import { Title, Label2 } from 'styles/Global';
import Brand from 'components/Brand/Brand';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWindowSizeDeviceType } from 'helper/useWindowSize';
import { HOME_ROUTE, INVOICES_ROUTE, LOGIN_ROUTE, LIVE_ORDERS_ROUTE, ADD_PRODUCTS_ROUTE, LIVE_PRODUCTS_ROUTE, SETTINGS_ROUTE } from 'constants/navigation';
import { faBarcode, faBicycle, faChartLine, faCog, faHome, faPlusCircle, faShoppingCart, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'react-bootstrap';

type SidebarItemProps = {
  href?: string;
  onClick?: (e: any) => void;
  index: number;
  icon?: IconDefinition;
  title: string;
  active: string;
  bold?: boolean;
  noIndent?: boolean;
  badge?: string;
  badgeVariant?: string;
};

const SidebarItem: React.FC<SidebarItemProps> = ({
  href = '#',
  onClick,
  index,
  icon,
  title,
  active,
  bold = false,
  noIndent = false,
  badge,
  badgeVariant = "primary",
}) => {
  return (
    <Link href={href}>
      <NavLink 
        key={index}
        onClick={onClick}
        className={`${href === active ? 'active' : ''} ${icon ? '' : 'subtitle'}`}
      >
        {!noIndent && !icon &&
          <>&nbsp;&nbsp;&nbsp;</>
        } 
        {icon &&
          <>
            <FontAwesomeIcon icon={icon} />
            &nbsp;&nbsp;&nbsp;
          </>
        }
        {title}
        &nbsp;&nbsp;
        {badge &&
          <Badge variant={badgeVariant}>{badge}</Badge>
        }
      </NavLink>
    </Link>
  );
};

type SidebarProps = {
  mobile?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ mobile = false }) => {
  const router = useRouter();
  const { smallTablet } = useWindowSizeDeviceType();
  const { pathname } = router;
  const { authState, authDispatch } = useAuth();
  const { user } = authState;
  const { firstName, lastName } = user;

  const handleLogout = (e: any) => {
    e.preventDefault();
    authDispatch({ type: 'LOGOUT' });
    router.push(LOGIN_ROUTE);
  };

  return (
    <Container>
      <MobileHeader>
        <Brand red />
        {mobile &&
          <>
            <Title>Partner Centre</Title>
            {user && <Label2>{firstName} {lastName}</Label2>}
          </>
        }
      </MobileHeader>

      <SidebarItem 
        href={HOME_ROUTE}
        index={1} 
        icon={faHome}
        title='Home'
        active={pathname}
      />

      <SidebarItem 
        href={LIVE_PRODUCTS_ROUTE}
        index={2} 
        icon={faShoppingCart}
        title='Inventory'
        active={pathname}
      />

      {!smallTablet && <SidebarItem 
        href={ADD_PRODUCTS_ROUTE}
        index={3} 
        icon={faPlusCircle}
        title='Add Products'
        active={pathname}
      />}

      {/* <SidebarItem 
        href={SCAN_ROUTE}
        index={4}
        icon={faBarcode}
        title='Scan'
        active={pathname}
      /> */}

      <SidebarItem 
        href={LIVE_ORDERS_ROUTE}
        index={6}
        icon={faBicycle}
        title='Live Orders'
        active={pathname}
        badge="BETA"
      />

      <SidebarItem 
        href={INVOICES_ROUTE}
        index={7} 
        icon={faChartLine}
        title='Invoices'
        active={pathname}
      />

      <SidebarItem 
        href={SETTINGS_ROUTE}
        index={8} 
        icon={faCog}
        title='Settings'
        active={pathname}
      />

      <Spacer />

      <SidebarItem 
        onClick={handleLogout}
        index={9}
        title='Log Out'
        active={pathname}
        bold
        noIndent
      />
    </Container>
  )
};

export default Sidebar;