import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useRouter } from 'next/router';
import { 
  LayoutWrapper,
  PageWrapper,
  SidebarWrapper,
  ContentWrapper,
  MobileSidebarWrapper,
  MobileContentWrapper,
} from './AppLayout.style';
import Modal from 'react-bootstrap/Modal';
import { withApollo } from 'helper/apollo';
import MobileNavBar from 'containers/NavBar/MobileNavBar';
import { DeviceType } from 'helper/useDeviceType';
import useAuth from 'contexts/auth/auth.context';
import Sticky from 'react-stickynode';
import Sidebar from 'components/Navigation/Sidebar';
import { useWindowSizeDeviceType } from 'helper/useWindowSize';
import { GET_SHOP } from 'api/shop';
import { ShopResponse, Shop } from 'models/Shop';
import useShopContext from 'contexts/shop/shop.context';
import { getCurrentUserUuid, getRefreshExpiryTime } from 'helper/user';
import { HOME_ROUTE, INVOICE_PDF_ROUTE, LOGIN_ROUTE } from 'constants/navigation';
import { identifyToMixpanel, setMixpanelUserProperty } from 'helper/Mixpanel';
import usePrevious from 'helper/usePrevious';
import Log from 'helper/monitoring';
import Package from 'package.json';
import { toHHMMSS } from 'helper/dates';

type LayoutProps = {
  deviceType: DeviceType;
};

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  deviceType
}) => {
  const { pathname } = useRouter();
  const { mobile, tablet, desktop } = useWindowSizeDeviceType();
  const [showDrawer, setDrawer] = useState(true);

  const navBarRef = useRef(null);

  const { authState } = useAuth();
  const { shopDispatch } = useShopContext();
  const { user } = authState;

  // --------------------------------------------
  // Analytics
  // --------------------------------------------

  const prevPathname = usePrevious(pathname);
  useEffect(() => {
    if (!process.browser) return;
    if (prevPathname !== pathname) newPageLoaded();
  }, [pathname]);

  const newPageLoaded = () => {
    setDrawer(false);
    window.scrollTo(0, 0);
    
    // Identify needs to be called every time you make a people.set call — to either create or update a people profile within Mixpanel.
    // https://community.mixpanel.com/sending-data-to-mixpanel-11/how-to-implement-people-set-in-js-api-585
    identifyToMixpanel();
  };

  useEffect(() => {
    const expiry = getRefreshExpiryTime();
    if (expiry) Log.info(`Logged in, expiring in ${toHHMMSS(expiry)}`, 'session');

    fetch('https://ipv4.jsonip.com').then(res => res.json()).then(data => {
      setMixpanelUserProperty({
        '$ip': data?.ip || '',
        'platform': 'Web',
        'version': Package.version,
        'last_session': new Date().toISOString(),
      });
    });

    return () => {
      setMixpanelUserProperty({ 'last_session' : (new Date()).toISOString() });
      identifyToMixpanel();
    };
  }, []);

  // --------------------------------------------
  // Shop
  // --------------------------------------------

  useQuery<ShopResponse>(GET_SHOP, {
    skip: !user?.shopUuid,
    variables: {
      id: user?.shopUuid
    },
    onCompleted: res => {
      shopDispatch({ type: "SET_SHOP", payload: res?.shop });
      setMixpanelUserProperty({ 'shop_id': res?.shop?.uuid, 'shop_name': res?.shop?.name });
    },
  });

  // --------------------------------------------
  // Render
  // --------------------------------------------

  const getLayoutClassName = (pathname: string): string => {
    switch (pathname) {
      case LOGIN_ROUTE: return "login";
      case INVOICE_PDF_ROUTE: return "fullscreen";
      default: return "";
    }
  };

  const layoutClassName = getLayoutClassName(pathname);

  if (layoutClassName.includes('fullscreen')) {
    return <>{children}</>;
  }

  const currentUserExists = user && !layoutClassName.includes('login');
  const navBarHeight = currentUserExists ? (navBarRef.current?.offsetHeight || 0) : 0;

  if (mobile || tablet) {
    return (
      <LayoutWrapper id="mobile-layout" className="mobile">

        <Sticky className="mobile-navbar">
          {currentUserExists && <MobileNavBar reference={navBarRef} onMenuClick={() => setDrawer(true)} />}
        </Sticky>

        <Modal
          show={showDrawer && currentUserExists}
          onHide={() => setDrawer(false)}
          dialogClassName='devo-dialog'
        >
          <MobileSidebarWrapper>
            <Sidebar mobile />
          </MobileSidebarWrapper>
        </Modal>

        <MobileContentWrapper id="mobile-content" navBarHeight={navBarHeight} className={layoutClassName}>
          {children}
        </MobileContentWrapper>
      </LayoutWrapper>
    );
  } else {
    return (
      <LayoutWrapper id="layout">
        <SidebarWrapper id="sidebar">
          {currentUserExists && <Sidebar />}
        </SidebarWrapper>

        <ContentWrapper id="content" className={layoutClassName}>
          {children}
        </ContentWrapper>
      </LayoutWrapper>
    );
  }
};

export default withApollo(Layout);