import React from "react";
import parseError from 'helper/error';

export default class ErrorBoundary extends React.Component {
  state = { error: null };

  static getDerivedStateFromError(error) {
    return { error: error };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error', error, errorInfo);
  }

  render() {
    return this.state.error ? (
      <div className="ErrorBoundary">
        <h3>Sorry there was a problem loading this page</h3>
        <p style={{ whiteSpace: 'pre-line' }} >
          {parseError(this.state.error)}
        </p>
      </div>
    ) : (
      this.props.children
    );
  }
}
