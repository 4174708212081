import fetch from 'node-fetch';
import Router from 'next/router';
import { ApolloLink } from '@apollo/client';
import { Observable } from 'rxjs';
import { onError } from "@apollo/link-error";
import { getAuthToken, getRefreshToken, setAuthToken, getRefreshExpiryTime, logout } from 'helper/user';
import Package from 'package.json';
import Log from 'helper/monitoring';
import { HOME_ROUTE, LOGIN_ROUTE } from 'constants/navigation';
// import { toHHMMSS } from './data/string';
// import { logAnalytics } from './gtm/helper';

// AUTHENTICATION LINK

export const authLink = new ApolloLink((operation, forward) => {
  const authToken = getAuthToken();
  var devoHeaders = {
    'Devo-Api-Key': process.env.DEVO_API_KEY,
    'Devo-App-Version': Package.version,
  };
  if (authToken && getRefreshExpiryTime() > 0) {
    devoHeaders['Devo-Auth-Token'] = authToken;
  }
  
  operation.setContext(({ headers }) => ({ headers: { ...devoHeaders, ...headers }}));
  return forward(operation);
});

// REFRESH TOKEN

// @ts-ignore
export const refreshTokenLink = onError(({ forward, graphQLErrors, networkError, operation }) => {
  // @ts-ignore;
  const statusCode = networkError?.statusCode;

  if (statusCode && statusCode >= 500 && statusCode < 600) {
    Log.error(`Received ${statusCode} on ${operation.operationName} request`, 'api', operation.variables);
  } else if (statusCode && statusCode !== 401) {
    Log.warning(`Received ${statusCode} on ${operation.operationName} request`, 'api', operation.variables);
  }
  
  if (statusCode === 401) {
    Log.info(`Received 401 on ${operation.operationName}, retrying auth...`, 'session', null);
    return new Observable(observer => {
      fetch(`${process.env.API_ACCOUNT_SERVICE}/auth/refresh`, {
        method: 'POST',
        body: JSON.stringify({
          refreshToken: getRefreshToken(),
        }),
        headers: {
          'Content-Type': 'application/json',
          'Devo-Api-Key': process.env.DEVO_API_KEY,
        }
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          if (response.status >= 400 && response.status < 500) {
            Log.debug('Refreshing auth token unauthorized', 'session', null);
          } else {
            Log.error(`Refreshing auth token returned ${response.status}`, 'auth', { status: response.status, response, json: response.json() });
          }
          throw { name : "UnauthorizedRefreshToken", message : "The request to refresh the auth token was unauthorized." }; 
        }
      })
      .then(response => {
        const authToken = response?.authToken;
        if (authToken) {
          setAuthToken(authToken);
          operation.setContext(({ headers = {} }) => ({
            headers: { ...headers, 'Devo-Auth-Token': authToken }
          }));

          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          };
  
          Log.info(`Auth refresh success, retrying ${operation.operationName}...`, 'session', null);
          forward(operation).subscribe(subscriber);
        } else {
          Log.error('Auth refresh success but no authToken', 'session', null);
        }
      })
      .catch(error => {
        // No refresh or client token available, we force user to login
        observer.error(error);
        Log.warning(`Failed to refresh token and save ${operation.operationName} ${statusCode}`, 'session', error);
        // logAnalytics('logout', { action: 'unauthorized_action' });
        logout();
        redirectToLogin();
      });
    })
  }
});

const redirectToLogin = () => {
  const path = window?.location?.pathname;

  const redirection = { 
    path: path || 'null',
    exceptions: {
      login: path?.includes(LOGIN_ROUTE) || false,
    }
  };

  const ineligiblePath = path && Object.values(redirection.exceptions).reduce((nxt, curr) => curr || nxt);
  Log.debug('LOGOUT REDIRECT AFTER FAILED REFRESH', 'session', { ...redirection, ineligiblePath });
  
  if (!path || !ineligiblePath) {
    const redirect = window.location.pathname;
    if (path && redirect != HOME_ROUTE) {
      Router.push(`${LOGIN_ROUTE}?redirect=${redirect}`);
    } else {
      Router.push(LOGIN_ROUTE);
    }
  }
};

// // Early refresh if refresh token expires in 1 month on live, or 30 mins on stable.
// export const prefetchAuthToken = () => {
//   const refreshToken = getRefreshToken();
//   if (!refreshToken) return;

//   const refreshInterval = process.env.DEVO_ENV === 'live' ? 2678400 : 1800;
//   const expiry = getRefreshExpiryTime();
//   if (expiry && expiry < refreshInterval && expiry > 0) {
//     Log.info('Prefetching new token...', 'session', { expiry: toHHMMSS(expiry) });
//     fetch(`${process.env.API_ACCOUNT_SERVICE}/auth/refresh`, {
//       method: 'POST',
//       body: JSON.stringify({ refreshToken }),
//       headers: {
//         'Content-Type': 'application/json',
//         'Devo-Api-Key': process.env.DEVO_API_KEY,
//       }
//     })
//     .then(response => {
//       if (response.status >= 200 && response.status < 300) {
//         return response.json();
//       } else {
//         if (response.status >= 400 && response.status < 500) {
//           Log.debug('Refreshing auth token unauthorized', 'session', null);
//         } else {
//           Log.error(`Refreshing auth token returned ${response.status}`, 'auth', { status: response.status, response, json: response.json() });
//         }
//         throw { name : "UnauthorizedRefreshToken", message : "The request to refresh the auth token was unauthorized." }; 
//       }
//     })
//     .then(response => {
//       Log.debug('Prefetch success, updating token', 'session', null);
//       const authToken = response?.authToken;
//       if (authToken) setAuthToken(authToken);
//     })
//     .catch(error => {
//       Log.warning('Failed to prefetch', 'session', error);
//     });
//   }
// };
