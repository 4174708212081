import React from 'react';
import styled from 'styled-components';
import LogoRed from 'image/devo-red-brand.png';
import LogoWhite from 'image/devo-white-brand.png';

const Wrapper = styled.div`
  img {
    margin-left: 10px;
    height: 30px;

    @media (max-width: 768px) {
      margin: 20px 0 0 0;
      height: 40px;
    }
  }
`;

type BrandProps = {
  red?: boolean;
  white?: boolean;
};

const Brand: React.FC<BrandProps> = ({
  red = false,
  white = false,
}) => {

  var src = LogoRed;
  if (white) src = LogoWhite;

  return (
    <Wrapper className='brand'>
      <img src={src} alt='Devo' />
    </Wrapper>
  )
};

export default Brand;